import React from "react";
import { Link } from "gatsby";
import Prod from "./prod";
import { motion } from "framer-motion";

export default ({ data }) => {
  return (
    <>
      <div className="container prouctLayout">
        <div className="productImage">
          <Prod images={data.images} />
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.5 }}
          className="productDis"
        >
          <p className="pBadge">{data.badge}</p>
          <div className="sku">
            <p>
              <span>SKU: </span>
              {data.sku}
            </p>
            <p>
              <span>Availability: </span>
              {data.availability}
            </p>
          </div>
          <h1 className="prodTitle">{data.title}</h1>
          <p className="price">
            <span className="mrp">₹{data.mrpPrice}</span>{" "}
            <span className="sellprice">₹{data.sellPrice}</span>
          </p>
          {/* <div className="prodColor">
            <p>
              <span>Available Colors: </span>
            </p>
            <div className="colors">
              <div className="pordColRed"></div>
              <div className="pordColBlue"></div>
              <div className="pordColGreen"></div>
              <div className="pordColYellow"></div>
            </div>
          </div> */}

          <div className="prodDetail">
            <h5>DESCRIPTION:</h5>
            <p>{data.disc}</p>
          </div>
          <Link to="/contact-us" className="prodQuote">
            Contact Us
          </Link>
        </motion.div>
      </div>
    </>
  );
};
