import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { motion } from "framer-motion";

export default (props) => {
  const [slideN, setslideN] = React.useState(0);

  return (
    <>
      <motion.div
        animate={{ x: 0 }}
        transition={{ delay: 3, when: "beforeChildren", staggerChildren: 0.3 }}
        className="slidethumb"
      >
        {props.images.map((item, index) => {
          return (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.3 }}
              onClick={() => setslideN(index)}
              key={index}
            >
              <img src={item} />{" "}
            </motion.div>
          );
        })}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1 }}
        className="mysliderrr"
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={props.images.length}
          infinite="true"
          currentSlide={slideN}
        >
          <Slider>
            {props.images.map((item, index) => {
              return (
                <Slide index={index}>
                  <img src={item} />
                </Slide>
              );
            })}
          </Slider>
          <ButtonBack className="sliderprevbtn">
            <p>{"<"}</p>
          </ButtonBack>
          <ButtonNext className="slidernextbtn">
            <p>{">"}</p>
          </ButtonNext>
        </CarouselProvider>
      </motion.div>
    </>
  );
};
